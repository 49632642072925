var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Chat", {
    staticClass: "mobile-chat",
    attrs: {
      teamID: _vm.getTeamID,
      roomID: _vm.getChatRoomID,
      context: _vm.getChatContext,
      placeholder: _vm.messagePlaceholder,
      canSendMessage: _vm.canSendMessage,
      isAnonymousChat: _vm.isAnonymousChat,
      messageText: _vm.message,
    },
    on: {
      onSelectedGameIDUpdate: function (data) {
        return _vm.$emit("onSelectedGameIDUpdate", data)
      },
      "update:messageText": function ($event) {
        _vm.message = $event
      },
      "update:message-text": function ($event) {
        _vm.message = $event
      },
    },
    scopedSlots: _vm._u([
      {
        key: "chat-header",
        fn: function () {
          return [
            _c("div", { staticClass: "mobile-chat__header" }, [
              _c("h2", [_vm._v("You’re chatting with the lobby")]),
              _vm._v(" Type “@host” to ping the host "),
            ]),
            _vm.availableChatContext.length > 1
              ? _c("ChatContextToggle", {
                  staticClass: "mobile-chat__context-toggle",
                  attrs: {
                    teamID: _vm.getTeamID,
                    contexts: _vm.availableChatContext,
                    selectedContext: _vm.getChatContext,
                  },
                  on: { localContextChange: _vm.onLocalContextChange },
                })
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "mobile-chat__close",
                on: {
                  click: function ($event) {
                    return _vm.$emit("closeChat")
                  },
                },
              },
              [_c("v-icon", { attrs: { dark: "" } }, [_vm._v("close")])],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "chat-messages",
        fn: function (ref) {
          var chat = ref.data
          return [
            _c(
              "v-layout",
              { staticClass: "mobile-chat__messages", attrs: { grow: "" } },
              [
                _c("Messages", {
                  attrs: {
                    messages: chat.messages,
                    teamID: chat.teamID,
                    contextRef: chat.contextRef,
                    canLazyLoadMessage: chat.canLazyLoadMessage,
                    context: chat.context,
                  },
                  on: {
                    LAZYLOAD_MESSAGE: chat.LAZYLOAD_MESSAGE,
                    onSelectedGameIDUpdate: chat.onSelectedGameIDUpdate,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "messages",
                        fn: function () {
                          return _vm._l(chat.messages, function (data, index) {
                            return _c(
                              "div",
                              {
                                key: index + "-" + data.key,
                                staticClass: "mobile-chat__message",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "mobile-chat__message-body",
                                    class: {
                                      "mobile-chat__message-body--right":
                                        _vm.user.id ===
                                        data.messagePayload.fromID,
                                      "mobile-chat__message-body--left":
                                        _vm.user.id !==
                                        data.messagePayload.fromID,
                                    },
                                  },
                                  [
                                    _vm.user.id !== data.messagePayload.fromID
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "mobile-chat__message-time",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  data.userDetail.firstname
                                                ) +
                                                " — " +
                                                _vm._s(
                                                  _vm._f("messageTime")(
                                                    data.messagePayload
                                                      .timestamp
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c("div", {
                                      staticClass: "mobile-chat__message-text",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$options.filters.normalizeMessage(
                                            data.messagePayload.message
                                          )
                                        ),
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            )
                          })
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }