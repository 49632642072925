



















































































import { mapGetters } from "vuex"
import ChatWrap from "@/components/Chat/ChatWrap.vue"
import Messages from "@/components/Chat/Messages.vue"
import ChatContextToggle from "@/components/Chat/ChatContextToggle.vue"
import { CHAT_CONTEXTS } from "@/components/Chat/constants"
import { RtbSpinner } from "@/components/ui"

export default ChatWrap.extend({
  name: "MobileChat",
  components: {
    ChatContextToggle,
    Messages,
    RtbSpinner
  },
  data() {
    return {
      CHAT_CONTEXTS,
      message: null
    }
  },
  computed: {
    ...mapGetters("auth", ["user", "client", "isSuper"])
  }
})
